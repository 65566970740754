.block_one {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20%;
    background-color: #f3f3f3;
}

.block_two {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

@media screen and (max-width: 768px) {

    .block_two {
        margin-bottom: 30px;
    }
}

