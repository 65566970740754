@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../fonts/OpenSans/OpenSans.ttf") format("truetype");
}

/* .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #007AFF;
    font-size: none;
    line-height: none;
    font-weight: none;
    margin-left: none;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    /* position: absolute;
    border-top: 7px solid #007AFF;
} */

.module_six_container {
    margin-top: 96px;
    width: calc(100% - 200px);
}

.module_six_container h1 {
    margin-bottom: 55px;
    text-align: center;
}

.module_six_container span {
    font-family: 'Pragmatica Extended';
    font-size: 40px;
    font-weight: 700;
    color: black;
}

.module_six_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.module_six_block_item {
    background-color: #F3F3F3;
    width: 49%;
    min-height: 280px;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 15px 30px;
    font-size: 16px;
}

.module_six_block_item p {
    text-align: start;
}


.module_six_block_item_title {
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
}



.module_six_block_item_title span {
    color: #007AFF;
    font-size: 23px;
    line-height: 23px;
    font-weight: 600;
}



@media screen and (max-width: 1200px) {
    .module_six_container {
        width: 95%;
        margin: 0 auto;
        margin-top: 40px;
    }

    .module_six_block_item_title .title {
        margin-left: 0;
    }

}

@media screen and (max-width: 1000px) {
    .module_six_block_item_title svg {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .module_six_block_item_title {
        margin-bottom: 17px;
        display: block;
    }


    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #007AFF;
        font-size: none;
        line-height: none;
        font-weight: none;
        margin-left: none;
        transition: transform 0.5s ease-in-out;

    }

    .triangle {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #007AFF;
        margin: 0px;
    }

    .module_six_block_item {
        background-color: #F3F3F3;
        width: 100%;
        height: 50px;
        min-height: 0;
        border-radius: 16px;
        margin-bottom: 20px;
        padding: 15px 30px;
        font-size: 16px;
        overflow: hidden;
        cursor: pointer;
        transition: height 0.5s ease-in-out;

    }

    .circle.open {
        transform: rotateZ(180deg);
        transition: transform 0.5s ease-in-out;

    }

    .module_six_block_item.open {
        height: 280px;
        transition: height 0.5s ease-in-out;

    }

    .module_six_block_item_title {
        display: flex;
        text-align: start;
    }

    .module_six_block_item_title .title {
        margin-left: 0;
    }




    .module_six_container {
        width: 90%;
    }

    .module_six_container h1 {
        text-align: start;
    }

    /* .module_six_block_item {

        background-color: #F3F3F3;
        width: 100%;
        border-radius: 16px;
        margin-bottom: 20px;
        padding: 30px;
        box-sizing: border-box;
        font-size: 20px;
        text-align: center;
        height: 250px;
    } */

    .module_six_container span {
        font-weight: 700;
        margin-bottom: 50px;
    }



    .module_six_container span {
        font-size: 16px;
    }

}