@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url("../src/fonts/DaysOne/DaysOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../src/fonts/Inter/Inter-Regular.ttf") format("truetype");
}



@font-face {
  font-family: "Pragmatica Extended";
  src: url("../src/fonts/PragmaticaExtended/PragmaticaExtended-Light.woff");
  font-weight: 200;
}

@font-face {
  font-family: "Pragmatica Extended";
  src: url("../src/fonts/PragmaticaExtended/PragmaticaExtended-Bold.woff"), url("../src/fonts/PragmaticaExtended/PragmaticaExtended-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Helios";
  src: url("../src/fonts/Helios/HeliosC-Italic.woff"), ;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../src/fonts/OpenSans/OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Open Sans";
  src: url("../src/fonts/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("../src/fonts/OpenSans/OpenSans-Bold.woff"), url("../src/fonts/OpenSans/OpenSans-Bold.woff2");
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;

}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol {
  list-style-type: none;
}

ul {
  list-style-type: none;
}

.main_container {
  margin: 0 auto;
  max-width: 1500px;

}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.main_title,
h1 {
  font-size: 45px;
  line-height: 45px;
  letter-spacing: 0px;
  font-family: "Pragmatica Extended";
  font-weight: 700;
  color: black;
}


.mb_title {
  margin-bottom: 45px;
}

.mb_container {
  margin-bottom: 45px;
}

.color_blue {
  color: #007AFF;
}

.mt_container {
  margin-top: 90px;
}

.mb_container {
  margin-bottom: 90px;
}

.pd_conrainer {
  padding-top: 45px;
}

.title,
h2,
h3,
h4,
h5 {
  font-size: 35px;
  line-height: 42px;
  font-family: "Pragmatica Extended";
  font-weight: 700;
  color: black;
}

.text {
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
  color: black;
  font-family: 'Open Sans' !important;
}



.btn {
  background: #007AFF;
  border-radius: 16px;
  color: #fff;
  box-shadow: none;
  outline: none;
  border: none;
  width: 250px;
  height: 60px;
  font-size: 18px;
  font-family: "Open Sans";
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
}

.mb_0 {
  margin-bottom: 0px;
}

@media screen and (max-width: 1300px) {


  .main_title,
  h1 {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0px;
    font-family: "Pragmatica Extended";
    font-weight: 700;
    color: black;
  }


  .mb_title {
    margin-bottom: 35px;
  }

  .mb_container {
    margin-bottom: 35px;
  }

  .pd_conrainer {
    padding-top: 35px;
  }

  .title,
  h2,
  h3,
  h4,
  h5 {
    font-size: 30px;
    line-height: 30px;
    font-family: "Pragmatica Extended";
    font-weight: 700;
    color: black;
  }

  .text {
    font-size: 15px;
    line-height: 30px;
    color: black;
  }

}

@media screen and (max-width: 1000px) {
  .main_title{
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 770px) {
  body {
    overflow-x: hidden;
  }

  .mb_title {
    margin-bottom: 15px;
  }

  .addresses_office_info_item .addresses_office_h3,
  .addresses_office_yandex_link {
    font-size: 18px;
  }

  .mt_container {
    margin-top: 30px;
  }

  .main_container {
    width: 100%;
    margin: 0 auto;
  }

  .title_mb_left {
    margin-left: 38px;
  }
  .main_title{
    font-size: 25px;
    line-height: 25px;
  }

  .title,
  h2,
  h3,
  h4,
  h5 {
    font-size: 20px;
    line-height: 22px;
  }

  .text {
    font-size: 15px;
    line-height: 20px;
  }

  .btn {
    width: 365px;
    height: 55px;
  }


}