@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Helios";
    src: url("../../fonts/Helios/ofont.ru_Helios.ttf") format("truetype");
}

.module_seven_container {
    width: calc(100% - 200px);
    min-height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding-top: 40px;
}

.module_seven_container a {
    text-decoration: none;
    color: #000000;
}

.module_seven_container_title {
    margin-top: 25px;
    margin-left: 35px;
    line-height: 60px;
    font-size: 44px;
    font-weight: 700;
    font-family: "Pragmatica Extended;";
    margin-bottom: 55px;
}

.module_seven_block {
    width: 100%;
    background-color: #3D3C3C;
    color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 40px;
    height: 500px;
}

.module_seven_block_one {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 50%;
    margin-right: 29px;
}

.module_seven_block_two {
    width: 50%;
}

.module_seven_block_one h2 {
    color: #fff;
    max-width: 420px;
}

.module_seven_block_one .text {
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    max-width: 400px;
    margin-bottom: 30px;
}



.module_seven_block_two_slider {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 90%;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px 10px 20px 10px;
    width: 100%;
}

.module_seven_block_two_slider_item {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.img_back {
    background-color: #000000;
    height: 90%;
}


.module_seven_block_two_slider_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    margin-right: 20px;
}

.img_back_box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.module_seven_block_two_slider_user {
    display: flex;

}

.module_seven_block_two_slider_user_block {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
    font-family: "Inter-Regular";
    font-style: normal
}

.module_seven_block_two_slider_user img {
    margin-top: 10px;
    width: 30px;
    height: 30px;
}

.module_seven_block_two_slider_text_wrapper {
    margin-top: 30px;
    width: 100%;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
}

.module_seven_block_two_slider_text {

    font-family: "Helios";
    font-style: italic;
    width: 100%;
    padding-left: 20%;
}

.btn {
    width: 300px;
}

.dots_module {
    display: flex;
    justify-content: space-between;
    width: 63px;
}

.dots {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #D9D9D9;
    cursor: pointer;
}

.module_seven_block_two_slider_manag {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.module_seven_block_two_slider_arrows {
    display: flex;
    gap: 10px;
    margin-right: -20px;
}

.module_seven_block_two_slider_left_arrow {
    transform: rotateY(180deg);
    cursor: pointer;
}

.module_seven_block_two_slider_right_arrow {
    cursor: pointer;

}

.module_seven_block_mobile {
    display: none;
}


@media screen and (max-width: 1400px) {
    .module_seven_container {
        width: 95%;
        margin: 0 auto;
    }

    .module_seven_container_title {
        margin-top: 0;
    }

}


@media screen and (max-width: 1000px) {

    .img_back {
        height: 300px;
    }

    .module_seven_container h2.title {
        display: block;
        width: 100%;
        background-color: #fff;
        padding: 30px 5%;
    }

    .module_seven_container h3.title {
        color: #fff;
        max-width: 100%;
        margin-bottom: 15px;
    }

    .module_seven_block_one .text {
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .module_seven_block {
        display: none;
    }

    .module_seven_block_mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        padding: 40px;
        border-radius: 30px;
        background-color: #3D3C3C;
    }

    /* #3D3C3C; */

    .module_seven_container {
        background-color: #fff;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 0;
        box-sizing: border-box;
    }

    .module_seven_container_title {
        margin: 0;
        line-height: 30px;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
        font-family: "Pragmatica Extended;";
        margin-bottom: 10px;
    }

    .module_seven_block_one p {
        width: 100%;
        font-size: 18px;
        margin-bottom: 30px;
    }


    .module_seven_block_one span {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;

    }




    .module_seven_btn {
        margin: 0 auto;
    }

    .module_seven_block_two {
        min-height: 500px;
        width: 100%;
    }


    .module_seven_block_two_slider_item {
        display: none;
        flex-direction: column;
        align-items: center;

    }

    .module_seven_block_two_slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        height: 470px;
        margin: 0 auto;

    }

    .module_seven_block_two_slider_text {
        margin-top: 0;
        margin-bottom: 15px;
        width: 100%;
        max-width: none;
    }

    .module_seven_block_one {
        width: 100%;
    }

    .module_seven_block_two_slider_arrows {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .module_seven_block_two_slider_manag {
        margin-top: 30px;
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .module_seven_block_two_slider_text_wrapper{
        align-items:start;
    }
    .module_seven_block_two_slider_block {
        display: block;
        width: 80%;
        margin-bottom: 15px;
        align-items: center;
    }

    .module_seven_block_two_slider_text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0;

    }




    .module_seven_block_two_slider_user {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .module_seven_block_two_slider_user img {
        margin-top: 10px;

        width: 30px;
        height: 30px;
    }


}