.contacts_container {
    width: calc(100% - 200px);
    max-width: 1500px;

}



@media screen and (max-width: 1000px) {
    .how_it_works_footer_title {
        padding: 0;
        color: black;
        text-align: center;
    }

    .contacts_container {
        margin-top: 45px;
        width: 95%;
        min-height: calc(100vh - 200px);
    }
}