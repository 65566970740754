@font-face {
    font-family: "PragmaticaExtended";
    src: url("../../fonts/PragmaticaExtended/PragmaticaExtended-Light.woff") format("truetype");
}


.spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #b6b6b6;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.select_btn_container {
    cursor: pointer;
}


.select_btn_container_send_currency {
    cursor: pointer;
    height: 45px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #666667;
    position: relative;
    padding-right: 15px;
    padding: 0 5px;
}

.select_btn_container_currency svg {
    width: 16px;
}

.select_options_currency {
    min-width: 80%;
    max-height: 155px;
    border-radius: 8px;
    background-color: #8d8d8d;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #CDCACA;
    transition: all ease 0.5s;
    z-index: 11;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 90%;
    left: 0;
}

.select_optionArrow svg {
    display: none;
    /* min-width: 100%;
    padding: 5px 0 10px 5px;
    border-bottom: 1px solid #CDCACA;
    display: flex;
    align-items: center;
    gap: 5px; */
}

.select_option {
    min-width: 100%;
    padding: 10px 12px 10px 25px;
    border-bottom: 1px solid #CDCACA;
    display: flex;
    align-items: center;
    gap: 10px;
}

.select_option:last-child {
    border-bottom: none;

}

.select_option:hover {
    background: #141313;
    color: #CDCACA;

}

.select_container__currency_title {
    cursor: pointer;
    text-align: center;
    margin-right: 5px;
    font-weight: 600;

}

@media screen and (max-width: 750px) {
    .select_btn_container_currency {
        width: 100%;
    }

}