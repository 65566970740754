.blog_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog_item_container h2 {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 30px;
    line-height: 30px;
}

.blog_item_container img {
    display: block;
    width: auto;
    height: 300px;
    margin: 0 auto;
    border-radius: 30px;
    width: 100%;
}

.blog_item_container {
    width: calc(100% - 200px);
    margin-bottom: 50px;
}

.blog_item_container h1 {
    max-width: 700px;
}

.blog_item_container .text {
    color: #333;

    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    padding: 30px 0px;
    /* 162.5% */
}



.blog_item_container .blog_item_block {
    width: 100%;
    background-color: #F3F2F2;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 20px;
    padding-left: 40px;
}

.blog_item_container p {
    margin-bottom: 20px;
}



.blog_item_block ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70%;
}

.blog_item_block ul li {
    cursor: pointer;
    margin-bottom: 15px;

}


/* BLOG2 */
.text_wrapper_items {
    width: 80%;
    margin: 0 auto;
}


.text_wrapper_item {
    list-style: inside;
    margin-bottom: 15px;
}

.text_wrapper_items .text {
    padding: 0;
}






@media screen and (max-width: 1200px) {

    .blog_item_container {
        margin: 0 auto;
        width: 95%;
        margin: 20px auto;
    }

    .blog_item_block span {
        margin: 0;
        margin: 15px auto;
    }



}

@media screen and (max-width: 700px) {
    .blog_item_container .text {
        padding: 15px 0;
    }

    .blog_item_container img {
        width: 100%;

    }

    .blog_item_container h1,
    .blog_item_container h2 {
        font-size: 20px;
        line-height: 20px;

    }

    .blog_item_container .text {
        font-size: 18px;
        line-height: 30px;
    }

    .blog_item_container {
        width: 90%;
        margin-bottom: 30px;
        margin-top: 0;
        font-size: 18px;
    }

    .blog_container h1 {
        font-size: 30px;
        line-height: 30px;
        text-align: center;
    }




}