@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}


.possibility_container {
    padding: 0 100px;
    width: 100%;
}

.text_blue {
    color: #007AFF;
}

/* title */

.possibility_title_wrapper {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.possibility_title {
    margin-bottom: 15px;
    text-align: center;
}

.possibility_title_text {
    text-align: center;
}

/* content */
.possibility_items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.possibility_item {
    width: calc(50% - 20px);
    min-height: 150px;
    background-color: #3D3C3C;
    margin-bottom: 40px;
    border-radius: 30px;
    padding: 30px;
}

.possibility_item_content_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.possibility_item_content_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: inherit;
    width: 120px;
    height: 120px;
}

.possibility_item_content_wrapper .text {
    width: calc(100% - 150px);
    color: white;
    font-weight: 400;
}

.possibility_item_content_image.possibility_item_content_image_1 {
    background-image: url('../../../public/assets/pages/main/posiibility/1.svg');
}

.possibility_item_content_image.possibility_item_content_image_2 {
    background-image: url('../../../public/assets/pages/main/posiibility/2.svg');
}

.possibility_item_content_image.possibility_item_content_image_3 {
    background-image: url('../../../public/assets/pages/main/posiibility/3.svg');
}

.possibility_item_content_image.possibility_item_content_image_4 {
    background-image: url('../../../public/assets/pages/main/posiibility/4.svg');
}

@media screen and (max-width: 1400px) {
    .possibility_container {
        padding: 0;
        width: 95%;
        margin: 0 auto;
    }


}


@media screen and (max-width: 1000px) {

    .possibility_title,
    .possibility_title_text {
        text-align: start;
    }

    .possibility_container {
        padding-top: 90px;
    }

    .possibility_item {
        width: calc(100%);
    }

    .possibility_item {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 750px) {

    .possibility_item_content_wrapper {
        flex-direction: column;
    }

    .possibility_item_content_wrapper .text {
        max-width: 400px;
        width: 100%;
    }

    .possibility_item_content_image {
        margin-bottom: 15px;
    }
}