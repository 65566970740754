@font-face {
    font-family: "Open Sans";
    src: url("../../../src/fonts/OpenSans/OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
    font-weight: 200;
}

.how_it_works {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.how_it_works_container {
    width: calc(100% - 200px);
}

.how_it_works_title {
    max-width: 750px;
}

.how_it_works_title_h2 {
    line-height: 30px;
    font-size: 20px;
    max-width: 700px;
}

.how_it_works_section_title {
    font-weight: 700;
    line-height: 60px;
    font-size: 44px;
    margin-bottom: 50px;
    margin-top: 70px;
}

.how_it_works_section_item span {
    font-size: 16px;
}

.how_it_works_section_item {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}

.how_it_works_section_item .text {
    color: #3D3C3C;

    font-size: 20px;
    font-style: normal;
    line-height: 30px;
}

.how_it_works_official_links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.how_it_works_official_links_item {
    width: 100%;
    position: relative;
    background-color: #3D3C3C;
    border-radius: 16px;
    padding: 60px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.how_it_works_official_links_item_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
    width: calc(100% - 250px);
    max-width: 500px;
}

.how_it_works_official_links_title {
    line-height: 60px;
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 50px;
}

.how_it_works_official_links_item_title {
    font-size: 25px;
    color: #f3f3f3;
    font-family: "Pragmatica Extended";
    margin-bottom: 10px;
    max-width: 600px;
}

.how_it_works_official_links_item_text {
    font-size: 20px;
    line-height: 30px;
    color: #F3F3F3;

    margin-bottom: 20px;

}

.how_it_works_official_links_item a {
    color: #007AFF;
}

.how_it_works_official_links_item_img_wrapper {
    width: 200px;
    height: 200px;
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.how_it_works_official_links_item_img_wrapper.official_link_1 {
    background-image: url('../../../public/assets/official_links/help_group.png');
}

.how_it_works_official_links_item_img_wrapper.official_link_2 {
    background-image: url('../../../public/assets/official_links/news_bot_link.png');
}

.how_it_works_official_links_item_img_wrapper.official_link_3 {
    background-image: url('../../../public/assets/official_links/news_bot_link.png');
}

.how_it_works_official_links_item button {
    background: #007AFF;
    border-radius: 16px;
    color: #fff;
    box-shadow: none;
    outline: none;
    border: none;
    width: 210px;
    height: 40px;
    font-size: 14px;
    display: flex;
    padding: 0 25px 0 15px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.how_it_works_slider {
    margin-bottom: 100px;
}

.slider_main_body {
    width: 100%;
    height: 650px;
    background-color: #f3f3f3;
    border-radius: 100px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.slider_main {
    position: relative;
}

.slider_main_left_arrow {
    position: absolute;
    top: 250px;
    left: -20px;
    cursor: pointer;
}

.slider_main_right_arrow {
    transform: rotateY(180deg);
    position: absolute;
    right: -20px;
    top: 250px;
    cursor: pointer;
}

.slider_body_item {
    width: 100%;
    height: 100%;
    display: none;
    justify-content: space-between;
    padding: 15px 40px 40px 40px;
    box-sizing: border-box;
}

._italic {
    font-style: italic
}

.slider_body_item span {
    font-weight: 600;
    font-size: 30px;
    display: flex;
    flex-direction: column;

}

.slider_body_item_text {
    width: 600px;
    margin-top: 50px;
}

.slider_body_item_text .title {
    color: #000;
    font-style: normal;
    font-weight: 600;
}

.slider_body_item_text h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 40px;

}

.slider_body_item_text h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;

}

.slider_body_item_text .mb_20 {
    margin-bottom: 20px;
}

.mt_80 {
    margin-top: 80px;
}

.slider_body_item_text .text {
    color: #3D3C3C;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
}

.slider_footer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.slider_footer_item {
    display: none;
    justify-content: space-around;
    width: 324px;
    height: 160px;
    background-color: #f3f3f3;
    border-radius: 24px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 3px;

}

.slider_footer_item_text {
    width: 100px;
    margin-top: 20px;
}

.slider_footer_item span {
    font-weight: 600;
    font-size: 6px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.slider_footer_item img {
    height: 153px;
    max-width: 75px;
    display: flex;
    align-self: center;
}

.slider_footer_item p {
    font-size: 5px;
    font-weight: 400;
}

.slider_footer_right_arrow {
    transform: rotateY(180deg);
    display: none;
}

.slider_footer_right_arrow {
    position: relative;
    left: 1.5em;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.slider_footer_right_arrow svg {
    position: relative;
    left: 1.5em;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.slider_footer_left_arrow {
    display: none;
    position: relative;
    left: 1.5em;
    width: 40px;
    height: 40px;
    cursor: pointer;

}

.slider_footer_left_arrow svg {
    width: 40px;
    height: 40px;

}

.how_it_works_slider_mobile img {
    width: 270px;
    height: 505px;
}

.how_it_works_slider_mobile_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.slider_main .slider_body_item_box_img img {
    display: block;
    /* width: 281px; */
    height: 100%;
    width: 270px;
}

.how_it_works_slider_mobile_item_dark {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #F3F3F3;
}


.how_it_works_slider_mobile_item_dark p {
    width: 70%;
}

.how_it_works_slider_mobile_item_dark span {
    width: 70%;
    font-size: 20px;
    font-weight: 600;
}

.how_it_works_slider_mobile_item p {
    width: 70%;
}

.how_it_works_slider_mobile_item span {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.how_it_works_slider_mobile {
    display: none;
    flex-direction: column;
    align-items: center;

}


/* addresses_office */
.addresses_office_link_support {
    text-decoration: none;
}

.addresses_office_container,
.addresses_office_partenrs_container {
    width: calc(100% - 200px);
}

.addresses_office_info_box,
.addresses_office_img_box_wrapper {
    max-width: 50%;
}

.addresses_office_info_box {
    width: 45%;
}

.addresses_office_img_box_wrapper {
    width: 400px;
    height: auto;
}

.addresses_office_img_box {
    height: 390px;
}


.addresses_office_info_item .addresses_office_h3 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 40px;
}

.addresses_office_info_item .addresses_office_h4 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 20px;
}

.addresses_office_info_item .text {
    margin-bottom: 20px;
}

.addresses_office_wrapper {
    display: flex;
    justify-content: space-between;
}

.addresses_office_img_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.addresses_office_img {
    width: 100%;
    height: 100%;
    border-radius: 25px;

}



.addresses_office_img_0 {
    width: 250px;
    height: 100%;
}

.addresses_office_img_1,
.addresses_office_img_2 {
    max-width: 100%;
}

.addresses_office_img_left_clmn {
    width: calc(70% - 10px);
}

.addresses_office_img_right_clmn_wrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: space-between;
}

.addresses_office_img_right_clmn {
    height: calc(50% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;

}

.addresses_office_img_left_clmn,
.addresses_office_img_right_clmn_item_1,
.addresses_office_img_right_clmn_item_2 {
    border-radius: 10px;
}

.addresses_office_img_left_clmn {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../public/assets/address_office/city_0.jpg');
}

.addresses_office_img_right_clmn_item_1 {
    height: calc(50% - 5px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../public/assets/address_office/city_1.jpg');
}

.addresses_office_img_right_clmn_item_2 {
    height: calc(50% - 5px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../public/assets/address_office/city_2.jpg');
}


.addresses_office_yandex_box {
    width: 100%;
}

.addresses_office_yandex_box__wrapper {
    width: 400px;
    margin-left: auto;
}



.addresses_office_yandex_box__link {
    display: flex;
    align-items: center;
}

.addresses_office_img_yandex_logo_map {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.addresses_office_link_support {
    color: #007AFF;
}

.addresses_office_yandex_link_a {
    text-decoration: none;
}

.pb_container_line {
    padding-bottom: 90px;
    border-bottom: 1px solid grey;
}

.mt_container_line {
    margin-top: 45px;
}

/* Партенры */
.addresses_office_partenrs_wrapper_title {
    display: flex;
    justify-content: space-between;
}

.addresses_office_partenrs_wrapper_title .text {
    width: 500px;
}

.addresses_office_partenrs_title {
    min-width: 50%;
}

/* Партенры  Грузия*/
.addresses_office_img_left_clmn_georgia {
    background-image: url('../../../public/assets/address_office/georgia_0.jpg');
}

.addresses_office_img_right_clmn_item_1_georgia {
    background-image: url('../../../public/assets/address_office/georgia_1.jpg');
}

.addresses_office_img_right_clmn_item_2_georgia {
    background-image: url('../../../public/assets/address_office/georgia_2.jpg');
}

/* google map link */
.addresses_office_google_box__wrapper {
    display: flex;
    align-items: center;
    min-height: 50px;
}

.addresses_office_img_google_logo_map {
    width: 50px;
    height: 50px;

}

/* Дубай */
.addresses_office_img_left_clmn_dubai {
    background-image: url('../../../public/assets/address_office/dubai_0.png');
}

.addresses_office_img_right_clmn_item_1_dubai {
    background-image: url('../../../public/assets/address_office/dubai_1.jpeg');
}

.addresses_office_img_right_clmn_item_2_dubai {
    background-image: url('../../../public/assets/address_office/dubai_2.png');
}


.addresses_office_google_link_a,
.addresses_office_yandex_link {
    text-decoration: none;
    color: #007AFF;
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    font-family: "Pragmatica Extended";

}



@media screen and (max-width: 1200px) {
    .addresses_office_partenrs_title {
        margin-bottom: 15px;
    }

    .addresses_office_partenrs_wrapper_title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .addresses_office_partenrs_wrapper_title .text {
        width: 100%;
    }

    .how_it_works_container {
        width: 95%;
    }

    .how_it_works_official_links_item_text {
        width: 100%;
    }

    .how_it_works_official_links_item_title {
        margin-bottom: 20px;
    }



    .addresses_office_container,
    .addresses_office_partenrs_container,
    .addresses_office_container {
        width: 95%;
    }

}

@media screen and (max-width: 1000px) {
    .how_it_works_container {
        margin-bottom: 0;
    }

    .slider_body_item_box_img {
        margin-bottom: 40px;
    }

    .how_it_works_container h2.title {
        margin-left: 5%;
    }

    .how_it_works_section_item .text {
        font-size: 15px;
        line-height: 20px;
    }


    .how_it_works_slider_mobile_item_dark span,
    .how_it_works_slider_mobile_item_dark p {
        margin-bottom: 15px;
    }

    .how_it_works_official_links_item_title {
        font-size: 18px;
        line-height: 25px;
    }

    .how_it_works_official_links_item_text {
        width: 100%;
        max-width: 100%;
    }

    .how_it_works_title {
        box-sizing: border-box;
        width: 95%;
        margin-top: 0;
        margin-left: 2.5%;
        margin-bottom: 15px;
        max-width: 350px;
    }

    .how_it_works_slider_mobile_item .title,
    .how_it_works_slider_mobile_item_dark .title {
        margin-bottom: 20px;
        width: 95%;

    }

    .how_it_works_slider_mobile_item h2.title,
    .how_it_works_slider_mobile_item_dark h2.title {
        line-height: 18px;
        font-size: 18px;
        margin-left: 0;
    }

    .how_it_works_slider_mobile_item h3.title,
    .how_it_works_slider_mobile_item_dark h3.title {
        line-height: 15px;
        font-size: 15px;
    }


    .how_it_works_slider_mobile_item,
    .how_it_works_slider_mobile_item_dark {
        margin: 40px 0;
        padding: 0 2.5%;
    }

    .how_it_works_slider_mobile_item .text,
    .how_it_works_slider_mobile_item_dark .text {
        margin-bottom: 20px;
        width: 95%;
        font-size: 14px;
    }

    .how_it_works_section_title {
        font-weight: 700;
        margin-bottom: 50px;
        margin-top: 70px;
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
    }

    .how_it_works_section_item {
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
        gap: 0;
    }

    .how_it_works_section_item div {
        margin-right: 20px;
    }

    .how_it_works_official_links_title {
        line-height: 30px;
        font-size: 30px;
        padding-left: 10%;
        padding-right: 10%;
        color: #FFF;
        margin-bottom: 0;
        padding-top: 15px;
    }

    .how_it_works_container {
        width: 100%;
    }

    .how_it_works_slider {
        display: none;
    }

    .how_it_works_slider_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .how_it_works_official_links {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 5%;
        padding-right: 5%;
    }

    .how_it_works_official_links_item {
        width: 100%;
    }

    .how_it_works_official_links_item_text {
        font-size: 15px;
        line-height: 20px;
    }

    .how_it_works_official_links_item {
        padding: 20px;
    }

    .how_it_works_official_links_item img {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 100px;
        height: 100px;
        border-radius: 0;
    }


    .addresses_office_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .addresses_office_info_box {
        display: flex;
        width: 100%;
        max-width: 100%;
        padding-right: 0;
    }

    .addresses_office_info_item,
    .addresses_office_info_item {
        width: 100%;
    }

    .addresses_office_info_item_1 {
        padding-right: 30px;
    }

    .addresses_office_img_box_wrapper,
    .addresses_office_yandex_box__wrapper {
        margin: 0 auto;
    }

    .addresses_office_img_box_wrapper {
        width: 500px;
        max-width: calc(100% - 20px);
    }

    .addresses_office_yandex_box__wrapper {
        width: 100%;
    }


    .addresses_office_yandex_box__link {
        justify-content: center;
    }

    .addresses_office_info_item .addresses_office_h3,
    .addresses_office_yandex_link {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 30px;
    }

    .addresses_office_info_item .addresses_office_h4 {
        font-size: 15px;
        line-height: 15px;
        margin-bottom: 15px;
    }

    .how_it_works_title_h2 {
        margin-left: 0;
        margin-left: 2.5%;
        width: 95%;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
        max-width: 400px;
    }






}

@media screen and (max-width: 750px) {

    .how_it_works_slider_mobile_item,
    .how_it_works_slider_mobile_item_dark {
        margin: 0;
        padding: 0 2.5%;
    }

    .how_it_works_official_links_item_title {
        font-size: 17px;
        line-height: 18px;
    }

    .slider_body_item_box_img {
        margin-bottom: 10px;
    }

    .how_it_works_title_h2 {
        width: 95%;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .addresses_office_info_box {
        flex-direction: column;
    }

    .addresses_office_info_item_1 {
        padding-right: 0;
    }

    .addresses_office_info_item {
        width: 100%;
    }

    .how_it_works_official_links_item_img_wrapper {
        width: 125px;
        height: 125px;
    }

    .how_it_works_official_links_item_body {
        width: calc(100% - 150px);
    }

    .how_it_works_section_item {
        margin-bottom: 15px;
    }

    .addresses_office_yandex_link,
    .addresses_office_google_link {
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {

    .how_it_works_official_links_item_img_wrapper {
        display: none;
    }

    .how_it_works_official_links_item_body {
        width: calc(100%);
    }

    .how_it_works_official_links_item_text,
    .how_it_works_official_links_item_title,
    .how_it_works_official_links_item button {
        width: 100%;
    }

    .how_it_works_official_links_item button {
        justify-content: center;
    }



}