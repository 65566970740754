@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../fonts/OpenSans/OpenSans.ttf") format("truetype");
}

.container_cards {
    padding: 0 100px;
    width: 100%;

}

.cards_wrapper {
    background-color: #3D3C3C;
    color: white;
    padding: 40px;
    border-radius: 30px;
}

/* title */

.cards_title_wrapper {
    margin: 0 auto;
    margin-bottom: 60px;
}

.cards_title_wrapper .title,
.cards_title_wrapper .text {
    color: white;
    text-align: center;
}

.cards_title_wrapper .title {
    margin-bottom: 15px;
}

.cards_title_text {
    font-weight: 200;
}

/* content */
.cards_content {
    margin-bottom: 45px;
}

.cards_content_item_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
    background-image: url('../../../public/assets/pages/main/cards/ok.svg');
    margin-right: 5px;
}


.cards_content_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
}



.cards_content_item {
    display: flex;
    justify-content: center;
    width: calc(100%/3 - 20px);
    margin-bottom: 100px;
}

.cards_content_item_text {
    width: calc(100% - 30px);
    max-width: 350px;
    color: white;
}

/* footer */

.cards_footer_wrapper {
    overflow: hidden;
    display: flex;
    gap: 20px;
}

.cards_footer_items {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    animation: scroll 20s linear infinite;
    gap: 20px;
}



.cards_footer_item {
    display: flex;
    justify-content: center;
    width: calc(100%/7 - 10px);
    margin-bottom: 40px;
}

.cards_footer_item {
    background-color: white;
    height: 60px;
    border-radius: 30px;
    width: 100px;
    padding: 5px;
}

.cards_footer_item_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 100%;
    border-radius: 30px;
    background-color: inherit;
}

.cards_footer_item_img_1 {
    background-image: url('../../../public/assets/pages/main/cards/1.svg');
}

.cards_footer_item_img_2 {
    background-image: url('../../../public/assets/pages/main/cards/2.svg');
}

.cards_footer_item_img_3 {
    background-image: url('../../../public/assets/pages/main/cards/3.svg');
}

.cards_footer_item_img_4 {
    background-image: url('../../../public/assets/pages/main/cards/4.svg');
}

.cards_footer_item_img_5 {
    background-image: url('../../../public/assets/pages/main/cards/5.svg');
}

.cards_footer_item_img_6 {
    background-image: url('../../../public/assets/pages/main/cards/6.svg');
}

.cards_footer_item_img_7 {
    background-image: url('../../../public/assets/pages/main/cards/7.svg');
}


@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% + 100px));
    }
}

@media screen and (max-width: 1400px) {
    .container_cards {
        padding: 0 2.5%;
    }

    .cards_content_item {
        display: flex;
        justify-content: center;
        width: calc(100%/2 - 20px);
        margin-bottom: 40px;
    }


}

@media screen and (max-width: 900px) {
    .cards_content_item {
        display: flex;
        justify-content: center;
        width: calc(100%);
        margin-bottom: 40px;
    }



}


@media screen and (max-width: 700px) {

    .cards_title_wrapper .title,
    .cards_title_wrapper .text {
        text-align: start;
    }

}

@media screen and (max-width: 600px) {
    .cards_footer_item {

        width: calc(100%/6 - 10px);
        margin-bottom: 40px;
    }

    .cards_footer_item_img {
        width: 100px;
    }


}