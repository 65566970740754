.select_btn_container_send_currency {
    cursor: pointer;
    height: 45px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-family: inherit;
    color: #666667;
    position: relative;
    gap: 10px;
}

.select_btn_container_currency {
    width: 300px;
    cursor: pointer;
    height: 55px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #666667;
    position: relative;
    padding-right: 15px;
    padding: 0 5px;
}


.select_container__send_currency_title {
    width: calc(100% - 21px);
    cursor: pointer;
    text-align: center;
    font-size: inherit;

}

@media screen and (max-width: 750px) {
    .select_container__send_currency_title {
        text-align: start;

    }
    
}


@media screen and (max-width: 750px) {
    .select_btn_container_currency {
        width: 100%;
    }
}