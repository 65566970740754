.module_nine_container {
    width: calc(100% - 200px);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    color: #3D3C3C;


}

.module_nine_block {
    min-height: 360px;
    /* padding: 18px 70px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #313030;
    border-radius: 16px;
    width: 95%;
    padding: 100px;
}

.module_nine_block .title {
    max-width: 600px;
    text-align: center;
    color: aliceblue;
    margin-bottom: 40px;
}

.module_nine_description .text {
    color: #3D3C3C;
    display: block;
    text-align: center;
    font-size: 20px;
    color: aliceblue;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 40px;
}

.module_nine_title {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #3D3C3C;
    line-height: 60px;
    font-size: 44px;
    font-weight: 700;
    max-width: 500px;
}

.module_nine_description {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3D3C3C;
    font-size: 20px;
    text-align: center;
}

.module_nine_footer_btn_1,
.module_nine_footer_btn_2 {
    width: 300px;
}


.module_nine_footer {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.module_nine_footer_btn_2 {
    /* border: 1px solid #6F7CFE; */
    color: #fff;
    border-radius: 16px;
    box-shadow: none;
    outline: none;
    height: 60px;
    font-family: "Inter-Regular";
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: linear-gradient(90deg, #007AFF, #6F7CFE, #BE7EFF);
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

}



@media screen and (max-width: 1400px) {
    .module_ten_container {
        width: 95%;
        margin: 0 auto;
        margin-top: 40px;
    }

    .module_nine_container {
        width: 100%;
    }


}

@media screen and (max-width: 1000px) {
    .module_nine_block {
        padding: 100px 0;
    }
    .module_nine_container{
        margin-top: 0;
    }

}

@media screen and (max-width: 768px) {
    .module_nine_block {
        width: 100%;
        min-height: 300px;
        padding: 20px;
    }

    .module_nine_title {
        line-height: 30px;
        font-size: 30px;
        text-align: center;
    }

    .module_nine_description span {
        font-size: 15px;
    }

    .module_nine_container {
        padding: 20px 5%;
        width: 100%;
        min-height: 360px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        box-sizing: border-box;
    }

    .module_nine_description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    .module_nine_description span {
        display: inline;
    }

    .module_nine_footer button {
        margin-bottom: 15px;
        width: 100%;
    }

    .module_nine_footer {
        width: 100%;
        flex-direction: column;
    }

    .module_nine_description .text {
        font-size: 16px;
        line-height: 20px;
    }

    .module_nine_block .title,
    .module_nine_description .text {
        margin-bottom: 30px;
    }

    .module_nine_footer button {
        margin-bottom: 0;
    }
}