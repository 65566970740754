.otzivi {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    min-height: calc(100vh - 225px);

}

.otzivi .module_seven_container {
    justify-content: start
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 768px) {
    .otzivi {
        margin-bottom: 0;
    }

}