@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

.module_three_container {
    width: calc(100% - 200px);
    display: flex;
    margin-top: 95px;
    margin-right: 0;
}

.module_three_block_one {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 60%;
}

.module_three_block_two {
    position: relative;
    padding-top: 30px;
    width: 40%;
    display: flex;
    align-items: start;
    justify-content: center;
}

#body {
    display: block;
    position: absolute;
    width: 320px;
    margin-top: 60px;
    margin-right: 0;

}

.item {
    width: 270px;
    position: relative;
    left: 0;
    display: none;
    top: 90px;
    border-radius: 20px;
}

.module_three_block_two video {
    height: 600px;
    display: none;
    position: relative;
    top: 90px;
    border-radius: 20px;

}

#module_three_block_two_title {
    color: #007AFF;
    top: 20px;
    right: 20px;
    position: absolute;
    width: 300px;
    font-family: "Pragmatica Extended";
    font-size: 18px;
    cursor: pointer;
}

@keyframes pulse {

    100% {
        transform: scale(1.1);
    }
}

.module_three_block_one_title {
    width: 800px;
    line-height: 60px;
    font-size: 44px;
    font-weight: 700;
    font-family: "Pragmatica Extended";
    color: black;
    display: flex;
    flex-wrap: wrap;
}

.module_three_block_one_title span {
    color: #007AFF;
    margin-right: 10px;
    margin-bottom: 40px;
}

.module_three_block_one_item {
    width: 95%;
    max-width: 550px;
    min-height: 155px;
    padding-bottom: 10px;
    border: 1px solid #D9D9D9;
    display: flex;
    padding: 15px;
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #f3f3f3;
    /* cursor: pointer; */
}

.module_three_block_one_item_block {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    margin-top: 25px;
    margin-bottom: 12px;
    padding-right: 15px;
}

.module_three_block_one_item_id {
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    padding-top: 25px;
    color: #007AFF;
}

.module_three_block_one_item_title .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: #007AFF;

}

.module_three_block_one_item_title {
    margin-bottom: 5px;
}

.module_three_block_one_item_description {
    display: inline-block;
}

.active {
    /* border: 1px solid #007AFF;
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box; */
}


@media screen and (max-width: 1400px) {
    .module_three_container {
        width: 95%;
        padding: 0;
        margin-top: 40px;
    }

    #module_three_block_two_title {
        width: 100%;
        text-align: right;
        margin-left: auto;
    }

}

@media screen and (max-width: 750px) {
    .module_three_block_one_item_id {
        padding-top: 30px;
    }

    .module_three_block_two {
        width: 100%;
        height: 700px;
    }

    #module_three_block_two_title {
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;

    }


    .module_three_container {
        margin-top: 30px;
        margin-bottom: 80px;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }



    .module_three_block_one_title {
        color: #000;

        text-align: center;
        font-family: 'Pragmatica Extended';
        line-height: 30px;
        font-size: 30px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;

    }

    .module_three_block_one {
        margin: 0 0;
    }

    .module_three_block_one {
        width: 100%;
    }



    .module_three_block_one_title {
        width: 90%;
        margin: 0 0;
        font-size: 30px;
        font-weight: 700;
        font-family: "Pragmatica Extended;";
        color: black;
        display: flex;
        justify-content: center;
        align-self: center;
        margin-bottom: 25px;
    }

    .module_three_block_one_title span {
        margin: 0 0;
    }

    .module_three_block_one_item {
        min-height: 120px;
        width: 100%;
    }


    .module_three_block_one_item_title .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;

    }

}