@font-face {
    font-family: "Inter-Regular";
    src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../fonts/OpenSans/OpenSans.ttf") format("truetype");
}


@font-face {
    font-family: "PragmaticaExtended";
    src: url("../../fonts/PragmaticaExtended/PragmaticaExtended-Bold.ttf") format("truetype");
}



.privacy_police_container {
    width: 90%;
    margin-top: 3%;
    margin-bottom: 5%;
}

.privacy_police_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy_police_container span {
    color: #666667;
    font-family: "Inter-Regular";
}

.privacy_police_container p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: "OpenSans";
}

.privacy_police_container h3 {
    margin-top: 30px;
    margin-bottom: 30px;

    font-size: 16px;
    font-weight: 500;
    font-family: "PragmaticaExtended";
}

.privacy_police_container h2 {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 500;
    font-family: "Inter-Regular";
}

.privacy_police_container h1 {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 600;
    font-family: "Inter-Regular";
}