.module_eight_container {
    width: calc(100% - 200px);
    margin-top: 95px;
}

.module_eight_one {
    width: 60%;
}

.module_eight_two {
    width: 40%;
}

.module_eight_one_title {
    line-height: 60px;
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 50px;
}

.module_eight_block {
    padding: 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.module_eight_one_item_text {
    color: black;
    font-family: "Pragmatica Extended" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 25px;
    padding: 15px;
    box-sizing: border-box;
    /* border: 1px solid #666667;
    border-radius: 25px;
    margin-top: 5px; */
    display: none;
    /* 125% */
}

.module_eight_one_item_text li.text {
    color: rgb(47, 47, 47);
    font-size: 16px;
}

.module_eight_one_item_block {
    margin-bottom: 5px;
}

.module_eight_one_item {
    color: #666667;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    min-height: 7%;
    border: 1px solid #666667;
    border-radius: 25px;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;

    gap: 15px;
}

.module_eight_one_item_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.module_eight_one_item div.text {
    font-family: "Pragmatica Extended" !important;
    font-size: 18px;

}



.module_eight_two img {
    display: block;
    height: 650px;
    width: 320px;
    margin: 0 auto;
    width: auto;
}

.module_eight_one_item_block p {
    padding: 15px;
    display: none;
    box-sizing: border-box;
    /* border: 1px solid #666667;
    border-radius: 25px; */
    margin-top: 5px;
}


@media screen and (max-width: 1400px) {
    .module_eight_container {
        width: 95%;
        margin: 0 auto;
        margin-top: 40px;
    }

}

@media screen and (max-width: 1000px) {
    .module_eight_container {
        width: 90%;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .module_eight_one_item {
        font-size: 18px;
    }

    .module_eight_one_title {
        margin-bottom: 0px;
        line-height: 30px;
        font-size: 30px;
        text-align: center;
    }

    .module_eight_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .module_eight_one {
        width: 100%;
    }

    .module_eight_two {
        width: 100%;
    }
    .module_eight_two img{
        display: none;
    }


}