.blog {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog_container {
    width: calc(100% - 200px);
    margin-top: 45px;
}

.blog h1 {
    line-height: 60px;
    font-size: 44px;
}

.blog_container h2 {
    margin-bottom: 20px;
}

.blog_container img {
    width: 95%;
}

.blog_block {
    display: flex;
    justify-content: space-between;
}

.blog_block_item_1 {
    min-height: 370px;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}



.blog_block_item_2 {
    min-height: 370px;
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    cursor: pointer;

}

.blog_block_item_1 img,
.blog_block_item_2 img {
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
    margin-top: 10px;
    border-radius: 30px;

}

.blog_container .text {
    color: #000;
    font-style: normal;
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {

    .blog_container {
        margin: 40px auto;
        width: 95%;
    }



}

@media screen and (max-width: 768px) {

    .blog_container {
        width: 95%;
    }





    .blog_item_container {
        width: 100%;
    }

    .blog_item_container {
        font-size: 20px;
    }

    .blog_item_container p {
        width: 95%;
        margin: 0 auto;
    }

    .blog_block {
        display: flex;
        flex-direction: column;
    }

    .blog_block_item_2 {
        margin: 0;
        width: 100%;
        margin-bottom: 30px;
    }

    .blog_block_item_2 img {
        width: 100%;
    }

}