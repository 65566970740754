.nav {
    position: sticky;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    box-shadow: 0 -5px 15px;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
}

.nav ul {
    width: 95%;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
}

.nav span {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 2%;
    cursor: pointer;
    font-family: 'Open Sans';
}

.box_logo {
    height: 30px;
    width: 160px;
}

.box_logo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nav ul li {
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
}

.li-1::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: #6cb0f8;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: -1;
    border-radius: 8px;
}

.li-1:hover::after {
    height: 100%;
}

.nav ul img {
    width: 160px;
    cursor: pointer;
}

.nav ul li a {
    text-decoration: none;
    color: #000;
}

.nav button {
    background: inherit;
    border: 1px solid #007AFF;
    border-radius: 16px;
    color: #007AFF;
    box-shadow: none;
    outline: none;
    width: 216px;
    height: 40px;
    font-size: 14px;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.nav_mobile {
    display: none;
}

.current {
    border-radius: 8px;
    background: #007AFF;
    color: #fff;
    /* box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.01); */
}

.nav_mobile_burger_open_block {
    display: none;
}

@media screen and (max-width: 850px) {
    .nav ul {
        display: none;
    }

    .nav {
        height: 50px;
    }

    .nav_mobile {
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        overflow: hidden;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .nav_mobile img {
        width: 160px;
        height: 60px;
        height: auto;
        cursor: pointer;
    }

    .nav_mobile_block {
        position: relative;
        width: 95%;
        height: 50px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        align-items: center;
        color: #000;
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 2%;
    }


    .nav_mobile_burger_open {
        cursor: pointer;
        display: none;

    }

    .nav_mobile_burger_open_block.openBurger {
        display: flex;
        animation: menuOpen 0.5s;
    }

    @keyframes menuOpen {
        from {
            width: 0;
        }

        to {
            width: 150vw;
        }
    }

    .nav_mobile_burger_open_block {
        position: relative;
        top: 46.45vh;
        height: 100vh;
        width: 150vw;
        min-width: 250px;
        padding: 20px;
        background: #007AFF;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        color: #fff;
        z-index: 222;
    }

    .nav_mobile_burger_open_block_one {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 20vh;
    }

    .nav_mobile_burger_open_block_burger {
        display: flex;
        justify-content: end;
    }

    .nav_mobile_burger_open_block_footer {
        margin-bottom: 10vh;
    }

    .nav_mobile_burger_open_block_footer_text {
        margin-top: 20px;
        text-align: center;
    }

    .nav_mobile_burger_open_block_items {
        display: flex;
        justify-content: center;
        gap: 40px;

    }

    .nav_mobile_burger_open_block_items .info {
        display: none;
    }

    .nav_mobile_burger_open_block_items_svg {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .nav_mobile_burger_open_block_items_svg svg {
        width: 25px;
        height: 25px;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav_mobile_burger_open_block_items_svg svg path {
        fill: #007AFF;
    }

    .nav_mobile_burger_open_block span {
        font-size: 20px;
        color: #fff;

    }
}