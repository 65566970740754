.spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #b6b6b6;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.select_container__title img {
    width: 25px;
    display: none;
}

.select_container__title {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: center;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.select_btn_container {
    width: 100%;
    height: 44px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    font-family: "Inter-Regular";
    color: #666667;
    position: relative;
}

.select_options {
    min-width: 80%;
    max-height: 155px;
    border-radius: 8px;
    background-color: #8d8d8d;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #CDCACA;
    transition: all ease 0.5s;
    z-index: 11;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 90%;
    left: 0;
}

.select_optionArrow {
    min-width: 100%;
    padding: 5px 0 10px 5px;
    border-bottom: 1px solid #CDCACA;
    display: flex;
    align-items: center;
    gap: 5px;
}

.select_optionArrow img {
    width: 6%;
}

.select_option {
    min-width: 100%;
    padding: 10px 12px 10px 25px;
    border-bottom: 1px solid #CDCACA;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.select_option img {
    width: 6%;

}

.select_option:last-child {
    border-bottom: none;

}

.select_option:hover {
    background: #141313;
    color: #CDCACA;

}

@media screen and (max-width: 768px) {
}

.select_options::-webkit-scrollbar,
.select_options::-webkit-scrollbar-track
{
    background: #000;;
    width: 5px;
    border-radius: 8px;
}

.select_options::-webkit-scrollbar-thumb {
    background: #000;

}


.select_options::-webkit-scrollbar-track-piece{
    background: #CDCACA;
}