@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../fonts/OpenSans/OpenSans.ttf") format("truetype");
}

@font-face {
    font-family: "PragmaticaExtended";
    src: url("../../fonts/PragmaticaExtended/PragmaticaExtended-Light.woff") format("truetype");
}

.id {
    color: #007AFF;
    font-family: "PragmaticaExtended";
    font-size: 18px;
}

.module_one_section_two_footer {
    display: flex;
    color: #fff;
}

.module_one_section_two_body {
    display: flex;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    justify-content: space-between;
    font-family: 'Inter-Regular';
    /* "Inter-Regular" */

}

.module_one_section_two_body_left_text span {
    font-size: 18px;
    font-family: "PragmaticaExtended";
}

.module_one_section_two_body_left_text {
    width: 50%;
    margin-top: 8px;
}

.module_one_section_two_body_left_text div {
    margin-top: 16px;
    font-size: 16px;
}

.module_one_section_two_body_right_text {
    width: 50%;
}

.module_one_section_two_body_right_text div {
    font-size: 18px;
    font-family: "PragmaticaExtended";
    margin-bottom: 8px;
    margin-top: 8px;


}



.spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #b6b6b6;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.module_one_section_two_body button {
    background: #007AFF;
    border-radius: 16px;
    color: #fff;
    box-shadow: none;
    outline: none;
    border: none;
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-family: "OpenSans";
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}


.module_one {

    display: flex;
    justify-content: center;
    height: calc(100vh - 130px);
    max-height: 1000px;
    min-height: 480px;
    align-items: center;
    margin-top: 40px;

}

.module_one_row {
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    width: 100%;
}

.module_one_section_one {
    max-width: 550px;
    margin-right: 35px;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.module_one_section_one .text {
    max-width: 400px;
    font-size: 22px;
    margin-bottom: 30px;
}


.module_one_title {
    display: flex;
    flex-direction: column;
}

.module_one_title_item {
    display: flex;
    flex-direction: column;
    line-height: 50px;
}

.module_one_telegram {
    color: #007AFF;
}

.module_one_description {
    display: flex;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    color: #666667;
}

.module_one_btn {
    background: #007AFF;
    border-radius: 16px;
    color: #fff;
    box-shadow: none;
    outline: none;
    border: none;
    width: 250px;
    height: 60px;
    font-size: 18px;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn:hover {
    transform: scale(1.05);
    transition: all 0.5s;
}




.module_one_section_two {
    margin-top: 15px;
    background-color: #3D3C3C;
    border-radius: 16px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 750px;
    width: 100%;
}

.module_one_section_two_title {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    font-family: "PragmaticaExtended";

}

.select_country {
    width: 100%;
    height: 67px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    color: #666667;
    margin-top: 50px;
}

.second_block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

}

.module_one_section_two_body_left {
    width: 62%;
}

.module_one_section_two_body_right {
    width: calc(38% - 20px);
}

.pay_summa {
    width: 100%;
    height: 55px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    color: #666667;
    margin-right: 20px;
    padding: 10px;
}

.pay_summa_input {
    font-size: 14px;
    width: calc(100% - 65px);
}

.nickname {
    margin-bottom: 20px;
    width: 100%;
    height: 55px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: #666667;
}

.pay_summa_rubl {
    height: 44px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #3D3C3C;
    font-weight: 500;
    justify-content: end;
    cursor: pointer;
}

.telsvg {
    box-sizing: border-box;
    padding: 20px 0;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.teleg_svg path {
    fill: #007AFF;
}

.btn .teleg_svg path {
    fill: white;
}

.footer .teleg_svg path {
    fill: white;
}

.footer_mobile .teleg_svg path {
    fill: white;
}

.currency_form {
    width: 47%;
    height: 71px;
    background-color: #FFFEFE;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    color: #666667;
}

.currency {
    margin-top: 30px;
    color: #fff;
    font-weight: 200;
    margin-bottom: 50px;
}

.currency span {
    font-weight: 200;
}

.get_summa {
    margin-top: 20px;
    color: #fff;
    font-weight: 400;
}

.get_summa span {
    font-weight: 400;
}

.pay_summa input {
    font-size: inherit;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #666667;
    caret-color: #666667;
    appearance: none;
    -webkit-appearance: none;
    font-family: inherit;
}

.nickname input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #666667;
    caret-color: #666667;
    -webkit-appearance: none;
    font-size: inherit;
    font-family: inherit;
}

input::-webkit-input-placeholder {
    color: #666667;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.module_one_section_two_footer .text {
    color: white;
    font-size: 12px;
    line-height: 15px;

}

.module_one_section_two_left_wrapper {
    padding-top: 30px;
    padding-left: 20px;
    width: 45%;
}

.module_one_section_two_right_wrapper {
    padding-top: 30px;
    padding-right: 10px;
    width: 50%;
}

.module_one_section_two_end {
    display: flex;
    justify-content: space-between;
}

.module_one_section_two_left_title,
.module_one_section_two_right_item_title,
.module_one_section_two_left_text,
.module_one_section_two_right_item_text {
    color: white;
}

.module_one_section_two_left_title {
    color: white;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 20px;
}

.module_one_section_two_right_item_title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
}

.module_one_section_two_left_text,
.module_one_section_two_right_item_text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
}

.module_one_section_two_left_text {
    max-width: 200px;
}

.module_one_section_two_footer {
    display: flex;
    justify-content: space-between;

}

.module_one_section_two_footer_left {
    max-width: 300px;
    width: 58%;
}

.module_one_section_two_footer_right {
    max-width: 300px;
    width: calc(45% - 10px);
}

.module_one_section_two_body_mobile {
    display: none;
}

@media screen and (max-width: 1400px) {


    .module_one {
        width: 95%;
        padding: 0;
        margin: 0 auto;
        margin-top: 40px;
    }

    .module_one_row {
        padding: 0;
    }



}

@media screen and (max-width: 1000px) {
    .module_one_title_item {
        display: block;
    }

    .main_title {
        letter-spacing: 1px;
    }

    .main_title span {
        display: inline;
    }

    .module_one {
        height: auto;
    }

    .module_one_row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }



    .currency {
        margin: 10px 0;
    }

    .module_one_section_one .text {
        max-width: 400px;
        line-height: 30px;
    }

    .select_btn_container_currency,
    .module_one_section_two_title {
        display: block;
        width: 100%;
    }

    .module_one_section_one,
    .module_one_section_two {
        margin: 0;

    }


    .module_one_section_one {
        width: 100%;
        max-width: none;
        height: auto;
        min-height: 150px;
    }

    .module_one_section_two {
        width: 100%;
        padding: 20px;
        max-width: 100%;

    }

    .second_block .pay_summa,
    .second_block .currency_form {
        width: 100%;
        max-width: 100%;
    }

    .pay_summa {
        margin-right: 15px;
    }

    .module_one_title_item {
        color: #000;

        font-family: 'Pragmatica Extended';
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        font-size: 30px;
        letter-spacing: 0.3px;
        max-width: 400px;
    }

    .module_one_title_item span {
        font-size: 30px;
        line-height: 30px;
    }

    .module_one_telegram {
        font-weight: 700;
        line-height: 30px;
        font-size: 30px;
        letter-spacing: 0.3px;
    }

    .module_one_description {
        display: block;
        margin: 0 auto;
        color: #666667;

        text-align: center;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 125% */
    }

    .module_one_btn {
        width: 97%;
        margin: 30px auto;
        justify-content: center;
        gap: 15px;
        padding: 10px 20px;
    }


    .module_one_section_one .btn {
        display: none;
    }
}

@media screen and (max-width: 750px) {
    .module_one_title_item {
        color: #000;

        font-family: 'Pragmatica Extended';
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        font-size: 30px;
        letter-spacing: 0.3px;
        max-width: 300px;
    }

    .module_one_title_item span {
        font-size: 25px;
        line-height: 25px;
    }

    .module_one_section_two_body_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .module_one_section_two_body_mobile .select_btn_container_currency {
        margin-bottom: 20px;
    }

    .module_one_section_two_body {
        display: none;
    }

    .module_one_title_item {
        font-size: 30px;
        line-height: 30px;
    }

    .module_one_section_two_body_left_text {
        width: 100%;
        font-size: 16px;
    }

    .module_one_section_two_body_right_text {
        width: 100%;
    }

    .module_one_section_two_footer {
        flex-direction: column;
        margin-top: 20px;
    }

    .module_one_section_two {
        max-width: 600px;
        margin: 0 auto;
    }

    .second_block,
    .module_one_section_two_body {
        flex-direction: column;
    }

    .pay_summa {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .module_one_section_one .text {
        max-width: 300px;
    }

    .module_one_section_one .text,
    .module_one_section_two_title {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .module_one_section_two_body_right,
    .module_one_section_two_body_left {
        width: 100%;
    }

    .module_one_section_two_body {
        gap: 0;
    }


    .module_one_section_two_footer_left,
    .module_one_section_two_footer_right {
        width: 100%;
        margin-bottom: 10px;
    }

    .module_one_section_two_end {
        flex-direction: column;
    }

    .module_one_section_two_right_wrapper,
    .module_one_section_two_left_wrapper {
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .module_one_section_two_left_text {
        max-width: 100%;
    }
}