@font-face {
    font-family: "DaysOne";
    src: url("../../fonts/DaysOne/DaysOne-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../../fonts/Inter/Inter-Regular.ttf") format("truetype");
}


.footer {
    background-color: #171717;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    box-sizing: border-box;
    font-family: 'Open Sans';
}

.footer_up {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    border-bottom: 1px solid grey;
}

.footer_up_right {
    display: flex;
    align-items: center;
    gap: 35px;
}

.footer_down {
    margin: 45px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer_down_left {
    display: flex;
    gap: 15px;
}

.footer_down_left span {
    text-decoration: underline;
}

.footer_down_right {
    display: flex;
    gap: 15px;

}

.footer_up_left {
    height: 30px;
    width: 160px;
}

.footer_up_left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.footer_mobile {
    background-color: #171717;
    width: 100%;
    color: #fff;
    display: none;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    box-sizing: border-box;
    font-family: 'Open Sans';
    height: auto;
    padding: 0 5%;
    padding-bottom: 30px;
}

.footer_mobile_item_one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.footer_mobile_item_one_img img {
    width: 160px;
    height: 35px;
    object-fit: cover;
    cursor: pointer;
    margin-left: -10px;
}

.footer_mobile_item_one_img {
    height: 40px;
}

.footer_mobile_item_two {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 40px;
}

.footer_mobile_item_three {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}


.footer_mobile_right_block_svg {
    min-width: 70px;
    display: flex;
    gap: 25px;
}

.copy {
    display: none;
}

.footer span {
    cursor: pointer;
}

.footer_mobile span {
    cursor: pointer;
}

.footer svg {
    cursor: pointer;
}

.footer_down_left span {
    cursor: pointer;
}

.footer_down_right span {
    cursor: default;
}

@media screen and (max-width: 1000px) {

    .footer_mobile {
        display: flex;
    }

    .footer {
        display: none;
    }


}